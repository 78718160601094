export default {
  name: 'TemplateFooterColumn',
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: () => '',
    },
    caption: {
      type: String,
      default: null,
    },
    index: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getItemListWithLimit() {
      return this.itemList.slice(0, this.firstShowingCount + this.row);
    },
  },
  data() {
    return {
      firstShowingCount: 8,
      row: 0,
      isShowMore: false,
    };
  },
  methods: {
    showMore() {
      this.row += 6;
      this.isShowMore = (this.row + this.firstShowingCount > this.itemList.length - 1);
    },
  },
};
