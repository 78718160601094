<template>
  <div class="col-12 col-md-12 text-center" v-if="show">
    <h3 class="row_title">{{caption}}</h3>
    <div class="row">
      <div class="col-12 col-sm-12" v-for="(item, inx) in getItemListWithLimit" :key="index+inx">
        <div class="footer">
          <ul class="footer_list" >
            <li>
              <a class="footer-item" :href="(path === '') ? item[0] :`/hotels-page/?${path}=${item[0]}`"> {{item[1]}}</a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="showmore-li col-12"><li v-if="!isShowMore"><a href="#" @click="showMore">{{$t("product-page.read-more")}}</a></li></ul>
    </div>
  </div>
</template>

<script>
import TemplateFooterColumn from './TemplateFooterColumn';

export default {
  name: 'FooterColumnMobileTheme1',
  mixins: [TemplateFooterColumn],
};
</script>

<style scoped>
  ul.showmore-li {
    list-style-type: none;
  }
  ul.showmore-li li a {
    color: #e9e9e95d;
    text-decoration: none;
    font-weight: 300;
    transition: 0.5s;
  }

  a.footer-item{
    color: #e9e9e9 !important;
    text-decoration: none !important;
    font-weight: 300;
    transition: 0.5s;
  }
  a {
    color: #007bff;
    text-decoration: none !important;
    background-color: transparent;
  }
  .show-more-text {
    opacity: 0.5;
  }
</style>
